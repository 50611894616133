#footer {
  position: relative;
  z-index: 1;
  background-color: $dark-green;
  color: white;
  .upper {
    padding-top: 80px;
    padding-bottom: 80px;
    > .inner {
      @include inner-pad;
      .left {
        .button {
          margin-top: 50px;
        }
      }
      .right {
        .logo {
          width: 135px;
          margin-top: 45px;
        }
      }
    }
  }
  .lower {
    background-color: black;
    padding-top: 80px;
    padding-bottom: 80px;
    > .inner {
      @include inner-pad;
      .left {
        display: flex;
        .column:first-of-type {
          flex-shrink: 0;
        }
        .column:last-of-type {
          padding: 0 4vw;
        }
      }
    }
  }
  .credit {
    background-color: rgba(0, 0, 0, 0.15);
    padding-top: 12px;
    padding-bottom: 12px;
    > .inner {
      @include inner-pad;
      p {
        margin-top: 0;
        line-height: 1.5;
        @include for-phone-smaller {
          span.hide {
            display: none;
          }
        }
      }
    }
  }
  @include for-small-desktop {
    .upper {
      padding-top: 60px;
      padding-bottom: 50px;
      > .inner {
        display: flex;
        flex-direction: column;
        .left {
          @include for-ipad-v {
            padding-right: 50px;
            br {
              display: none;
            }
            @include for-phone {
              padding-right: 0;
            }
          }
        }
        .right {
          .logo {
            width: 100px;
            float: right;
          }
        }
      }
    }
    .lower {
      padding-top: 50px;
      padding-bottom: 50px;
      > .inner {
        @include for-phone {
          display: block;
          .left {
            display: block;
            // .column:first-of-type { flex-shrink:1; min-width:120px; padding-right:20px; }
            .column:last-of-type {
              padding: 40px 0;
            }
          }
        }
      }
    }
  }
}

.grid-10-2 {
  display: grid;
  grid-gap: $grid_gap;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: 'left left left left left left left left left left right right';
  .left {
    grid-area: left;
  }
  .right {
    grid-area: right;
  }
}
