@font-face {
  font-family: 'poynter-oldstyle-display';
  src: url('/fonts/poynter-oldstyle-display-600.woff2') format('woff2'),
    url('/fonts/poynter-oldstyle-display-600.woff') format('woff'),
    url('/fonts/poynter-oldstyle-display-600.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'poynter-oldstyle-display';
  src: url('/fonts/poynter-oldstyle-display-700.woff2') format('woff2'),
    url('/fonts/poynter-oldstyle-display-700.woff') format('woff'),
    url('/fonts/poynter-oldstyle-display-700.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'futura-pt';
  src: url('/fonts/futura-pt-400.woff2') format('woff2'),
    url('/fonts/futura-pt-400.woff') format('woff'),
    url('/fonts/futura-pt-400.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'futura-pt';
  src: url('/fonts/futura-pt-500.woff2') format('woff2'),
    url('/fonts/futura-pt-500.woff') format('woff'),
    url('/fonts/futura-pt-500.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'futura-pt';
  src: url('/fonts/futura-pt-600.woff2') format('woff2'),
    url('/fonts/futura-pt-600.woff') format('woff'),
    url('/fonts/futura-pt-600.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'futura-pt';
  src: url('/fonts/futura-pt-700.woff2') format('woff2'),
    url('/fonts/futura-pt-700.woff') format('woff'),
    url('/fonts/futura-pt-700.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'trade-gothic-next';
  src: url('/fonts/trade-gothic-next-300.woff2') format('woff2'),
    url('/fonts/trade-gothic-next-300.woff') format('woff'),
    url('/fonts/trade-gothic-next-300.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'trade-gothic-next';
  src: url('/fonts/trade-gothic-next-400.woff2') format('woff2'),
    url('/fonts/trade-gothic-next-400.woff') format('woff'),
    url('/fonts/trade-gothic-next-400.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}
