.service-list {
  display: grid;
  grid-gap: $grid_gap;
  grid-template-columns: repeat(12, 1fr);
  margin-top: 60px;
  @include for-small-desktop {
    row-gap: 40px;
  }
  @include for-ipad-v {
    margin-top: 40px;
  }
  > .item {
    grid-column: span 6;
    position: relative;
    @include for-phone {
      grid-column: span 12;
    }
    a {
      display: block;
      position: relative;
      > .text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 30px;
        padding-right: 39%;
        @include for-small-desktop {
          position: relative;
          padding: 20px 0 0;
        }
      }
      > img {
        opacity: 0.25;
      }
    }
    // &:last-of-type { grid-column-start:4; grid-column-end:10 }
  }
}

#service-item-page {
  &.service-corporate-formation .block.block-2 > .inner {
    grid-template-areas: 'image image image image . text text text text text text text';
  }
  .block {
    padding-bottom: 100px;
    @include for-small-desktop {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    &.title {
      padding-bottom: 0;
    }
    &.block-1,
    &.block-split,
    &.block-2,
    &.block-3 {
      > .inner {
        display: grid;
        grid-gap: $grid_gap_vertical $grid_gap;
        grid-template-columns: repeat(12, 1fr);
        @include for-ipad-v {
          display: block;
        }
        .text {
          padding-right: 0;
        }
      }
    }
    &.block-1 > .inner {
      grid-template-areas: 'text text text text text text . image image image image image';
    }
    &.block-split {
      padding-top: 0;
      > .inner {
        grid-template-areas: 'text text text text text text . text-2 text-2 text-2 text-2 text-2';
        > div > *:first-child {
          margin-top: 0;
        }
        @include for-ipad-v {
          .text {
            margin-top: -20px;
          }
          .text-2 {
            margin-top: 30px;
          }
        }
      }
    }
    &.block-2 > .inner {
      grid-template-areas: 'image image image image image image . text text text text text';
      > div > *:first-child {
        margin-top: 0;
      }
      @include for-ipad-v {
        .image + .text {
          margin-top: 40px;
        }
      }
    }
    &.block-3 > .inner {
      grid-template-areas: 'text text text text text . image image image image image image';
      > div > *:first-child {
        margin-top: 0;
      }
    }
    &.block-3 {
      position: relative;
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('/images/content/stock-photo-aerial-view-of-shanghai-city-in-the-morning-734684380.jpg');
        background-size: cover;
        opacity: 0.3;
      }
      .inner {
        position: relative;
        z-index: 1;
        @include for-ipad-v {
          .text h4 {
            margin-top: 40px;
          }
        }
      }
    }
    // .case-list {
    //   .item {
    //     @include for-small-desktop {
    //       &:nth-of-type(3) {
    //         display: none;
    //       }
    //     }
    //   }
    // }
  }
}
