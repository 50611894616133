.case-list {
  display: grid;
  grid-gap: $grid_gap_vertical $grid_gap;
  grid-template-columns: repeat(12, 1fr);
  margin-top: 60px;
  @include for-ipad-v {
    margin-top: 40px;
  }
  .item {
    > a {
      position: relative;
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 62.5%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    grid-column: span 4;
    @include for-small-desktop {
      grid-column: span 6;
    }
    @include for-phone {
      grid-column: span 12;
    }
    img {
      width: 100%;
    }
  }
}
#home .case-list .item {
  @include for-small-desktop {
    &:last-of-type {
      display: none;
    }
  }
}

#case-item-page {
  .block {
    padding-bottom: 100px;
    &.block-1,
    &.block-2 {
      > .inner {
        display: grid;
        grid-gap: $grid_gap_vertical $grid_gap;
        grid-template-columns: repeat(12, 1fr);
        grid-template-areas: 'left left left left left left . right right right right right';
        .left {
          grid-area: left;
          .task {
            margin-top: 50px;
          }
        }
        .right {
          grid-area: right;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .block-1 {
    padding-bottom: 70px;
    > .inner {
      @include for-ipad-v {
        display: block !important;
      }
    }
  }
  .block-2 {
    padding-top: 70px;
    > .inner {
      @include for-ipad-v {
        display: block !important;
        .action {
          margin-top: 50px;
        }
      }
    }
  }
  // .case-list {
  //   .item {
  //     @include for-small-desktop {
  //       &:nth-of-type(3) {
  //         display: none;
  //       }
  //     }
  //   }
  // }
}
