$grid_gap: 18px;
$grid_gap_vertical: 60px;

@mixin inner-pad {
  padding-left: 125px;
  padding-right: 125px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  @include for-small-desktop {
    padding-left: 100px;
    padding-right: 100px;
  }
  @include for-ipad-v {
    padding-left: 50px;
    padding-right: 50px;
  }
  @include for-phone() {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.pad-sides > .inner {
  @include inner-pad;
}

body {
  background-color: black;
}

.main {
  position: relative;
  z-index: 1;
  background-color: white;
  padding-top: 50px;
  &#home {
    @include for-small-desktop {
      padding-top: 0;
    }
  }
  .block {
    width: 100%;
    margin: auto;
    padding-top: 100px;
    @include for-ipad-v {
      .text {
        h4 {
          margin-top: 0;
        }
        + .image {
          margin-top: 40px;
        }
      }
      .image {
        width: 100%;
        margin-top: 0px;
        + .text {
          h4 {
            margin-top: 40px;
          }
        }
      }
    }
    @include for-small-desktop {
      padding-top: 80px;
    }
    &.text-image > .inner {
      display: grid;
      grid-gap: $grid_gap_vertical $grid_gap;
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas: 'text text text text text . image image image image image image';
      &.narrower {
        grid-template-areas: 'text text text text text text text . image image image image';
      }
      @include for-ipad-v {
        display: block;
      }
      .text {
        grid-area: text;
      }
      .text-2 {
        grid-area: text-2;
      }
      .image {
        grid-area: image;
        img {
          width: 100%;
        }
      }
    }
    &.image-bigger > .inner {
      grid-template-areas: 'text text text text text image image image image image image image';
      .text {
        padding-right: 10%;
      }
      @include for-small-desktop {
        display: block;
        .text {
          padding-right: 0;
          + .image {
            margin-top: 40px;
          }
        }
      }
    }
    &.pad-bottom {
      padding-bottom: 120px;
      @include for-ipad-v {
        padding-bottom: 80px;
      }
    }
    &.title {
      margin-bottom: -20px;
    }
    &.divider {
      margin-bottom: -40px;
      .line {
        display: inline-block;
        width: 100px;
        height: 1px;
        border-top: solid 1px black;
      }
      &.center {
        text-align: center;
      }
    }
    &.image-full {
      img {
        width: 100%;
      }
      @include for-ipad-v {
        overflow: hidden;
        img {
          width: 200%;
          max-width: none;
          margin-left: -50%;
        }
      }
    }
  }
}

.center-holder {
  text-align: center;
}
.text > *:first-child {
  margin-top: 0;
}

.is-active {
  font-weight: bold;
}
