.scroll-down {
  position: absolute;
  width: 100%;
  button {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 54px;
    height: 54px;
    border: solid 1px white;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    background-color: $green;
    transition: transform 0.3s;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 53%;
      width: 17px;
      border-top: solid 1px white;
    }
    &::before {
      right: 45.2%;
      transform: rotate(45deg);
    }
    &::after {
      left: 45.2%;
      transform: rotate(-45deg);
    }
    &:hover {
      transform: translateY(-5px);
    }
  }
}
