.loader {
  @include fix-fill;
  z-index: 10;
  background-color: black;
  background-image: url('/images/ui/loader.svg');
  background-size: 60px;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 1s, visibility 1s;
  &.first-load {
    z-index: 11;
    opacity: 1;
    &.loaded {
      opacity: 0;
      visibility: hidden;
    }
  }
}
