#about {
  .block {
    padding-top: 100px;
    padding-bottom: 100px;
    @include for-phone {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    &.title {
      margin-bottom: 0;
    }
    &.text-image > .inner {
      display: flex;
      @include for-phone {
        flex-direction: column;
      }
      .image {
        width: 350px;
        flex-shrink: 0;
        @include for-small-desktop {
          width: 250px;
        }
        @include for-ipad-v {
          width: 220px;
        }
      }
      .text {
        width: 100%;
        @include for-phone {
          margin-top: 50px;
        }
        .inner {
          max-width: 700px;
          padding-left: 100px;
          @include for-ipad-v {
            padding-left: 70px;
          }
          @include for-phone {
            padding-left: 0;
          }
        }
      }
    }
    &.image-right > .inner {
      flex-direction: row-reverse;
      @include for-phone {
        flex-direction: column;
      }
      .text {
        .inner {
          padding-left: 0;
          padding-right: 100px;
          @include for-ipad-v {
            padding-right: 70px;
          }
          @include for-phone {
            padding-right: 0;
          }
        }
      }
    }
  }
  .team {
    .team-list {
      margin-top: 50px;
      @include for-small-desktop {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include for-phone-smaller {
          display: block;
        }
      }

      .team-item {
        display: flex;
        @include for-small-desktop {
          display: block;
          width: 47%;
          &:nth-of-type(2) {
            margin-top: 0 !important;
          }
          @include for-phone-smaller {
            width: 100% !important;
            + .team-item {
              margin-top: 50px !important;
            }
          }
        }
        .image {
          width: 240px;
          flex-shrink: 0;
          img {
            width: 100%;
          }
          @include for-small-desktop {
            width: 100%;
          }
        }
        .text {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-grow: 1;
          padding: 0 60px;
          background-color: white;
          h4.caption {
            position: absolute;
            top: 25px;
            right: 25px;
            font-weight: 400;
            font-size: 12px;
            @include for-ipad-v {
              display: none;
            }
          }
          @include for-small-desktop {
            padding: 40px;
          }
          @include for-phone {
            padding: 20px;
          }
          h4 {
            margin-top: 0;
          }
          h2 {
            margin-top: 6px;
          }
          p {
            margin-top: 12px;
          }
        }
        + .team-item {
          margin-top: 25px;
        }
      }
    }
  }
}
