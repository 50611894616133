#home {
  .block {
    &.cases,
    &.services {
      > .text {
        text-align: center;
      }
      .button-holder {
        margin-top: 30px;
        @include for-ipad-v {
          margin-top: 0px;
        }
      }
    }
    &.services {
      padding-bottom: 100px;
      @include for-small-desktop {
        padding-bottom: 80px;
      }
    }
  }

  @include for-small-desktop {
    .block.services {
      .item {
        // &:nth-of-type(3),
        // &:nth-of-type(4) {
        //   display: none;
        // }
      }
    }
  }
  @include for-ipad-v {
    .block.intro .image {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
    .divider {
      display: none;
    }
  }
}
