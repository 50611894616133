.breadcrumb {
  position: relative;
  z-index: 1;
  background-color: $green;
  border-top: solid 1px $grey;
  padding-top: 8px;
  padding-bottom: 8px;
  p {
    margin: 0;
    line-height: 1.4;
    .arrow-right {
      margin: 0 11px;
    }
  }
}

.arrow-right {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
}
