.transition-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s;
  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &.fade-exit {
    opacity: 0;
  }
}
