#contact {
  .block {
    &.title {
      padding-bottom: 100px;
    }
    &.phone-email {
      padding-top: 70px;
      padding-bottom: 70px;
      > .inner {
        display: flex;
        @include for-small-desktop {
          display: block;
        }
        .contact-item {
          > .inner .icon-holder {
            display: block;
            border-radius: 50%;
            overflow: hidden;
          }
          @include for-small-desktop {
            &:first-of-type {
              margin-left: 48px;
            }
          }
          + .contact-item {
            margin-left: 18%;
          }
        }
        h4 {
          margin-top: 0;
          min-width: 34px;
        }
      }
    }
    &.image-address {
      padding-top: 70px;
      padding-bottom: 160px;
      @include for-small-desktop {
        padding-bottom: 110px;
        @include for-phone {
          padding-bottom: 70px;
        }
      }
      > .inner {
        display: flex;
        @include for-small-desktop {
          display: block;
        }
        .contact-item + .contact-item {
          padding-top: 30px;
        }
        > .image {
          width: 50%;
          min-width: 330px;
          @include for-small-desktop {
            width: 300px;
            min-width: auto;
          }
          @include for-small-desktop {
            width: 100%;
          }
          img {
            width: 100%;
          }
        }
        > .text {
          padding-left: 110px;
          @include for-small-desktop {
            padding-left: 100px;
          }
          @include for-small-desktop {
            padding-left: 90px;
          }
          @include for-small-desktop {
            padding-left: 0;
          }
        }
      }
    }
  }
  .contact-item {
    position: relative;
    @include for-small-desktop {
      margin-left: 48px !important;
      + .contact-item {
        margin-top: 40px;
        padding-top: 0 !important;
      }
    }
    > .inner {
      position: relative;
      .icon {
        width: 34px;
        height: 34px;
        position: absolute;
        top: -7px;
        left: -48px;
      }
    }
  }
}
