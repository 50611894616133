#menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.5s;
  &.up {
    transform: translateY(-100%);
  }
  > .outer {
    position: relative;
    > .loaded-wrap {
      transition: transform 0.5s ease 1s;
      &.up {
        transform: translateY(-100%);
      }
      > .inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 20px 40px;
        _background-color: rgba(0, 0, 0, 0.18);
        _background-color: rgba(74, 93, 90, 0.8);
        background-color: rgba(37, 46, 45, 0.8);

        .logo {
          width: 100px;
        }
        ul {
          list-style: none;
          li {
            display: inline-block;
            a {
              padding: 10px 20px;
              display: block;
              > .inner {
                position: relative;
                &::after {
                  content: '';
                  position: absolute;
                  bottom: -8px;
                  left: 0;
                  width: 100%;
                  border-bottom: solid 3px white;
                  transform: scaleX(0.5);
                  opacity: 0;
                  transition: transform 0.15s, opacity 0.3s;
                }
              }
              &:hover,
              &.active {
                > .inner::after {
                  transform: scaleX(1);
                  opacity: 1;
                }
              }
            }
            &:last-of-type a {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}
@include for-ipad-v {
  #menu {
    height: 60px;
    transform: translate3d(0, -1px, 0); // ios bug - top crack
    .thumb {
      position: absolute;
      z-index: 1;
      top: 11px;
      right: 38px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50px;
      height: 40px;
      padding: 12px;
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.5s ease 1.5s;
      &.in {
        opacity: 1;
      }
      @include for-phone {
        right: 18px;
      }
      > div {
        width: 100%;
        height: 2px;
        background-color: white;
        transition: background-color 0.5s, opacity 0.5s, transform 0.5s;
      }
    }
    > .outer {
      position: relative;
      transition: transform 0.5s;
      transform: translateY(calc(-100% + 60px));
      > .loaded-wrap {
        > .inner {
          padding: 0;
          flex-direction: column-reverse;
          align-items: flex-start;
          padding: 0;
          .logo {
            width: 133px;
            padding: 10px 30px 9px;
            margin-left: 20px;
            @include for-phone {
              margin-left: 0;
            }
          }
          ul {
            padding: 15px 0 7px;
            li {
              display: block;
              a {
                display: inline-block;
                padding: 7px 50px;
                font-size: 13px;
                @include for-phone {
                  padding: 7px 30px;
                }
                > .inner::after {
                  bottom: -5px;
                  border-bottom-width: 2px;
                  transform-origin: left;
                }
              }
            }
          }
        }
      }
    }
    &.open,
    &.open.up {
      transform: translate3d(0, -1px, 0); // ios bug - top crack
      .thumb {
        .one {
          transform: rotate(45deg) translate3d(5px, 5px, 0);
        }
        .two {
          opacity: 0;
        }
        .three {
          transform: rotate(-45deg) translate3d(5px, -5px, 0);
        }
      }
      > .inner {
        transform: none;
      }
      > .outer {
        transform: none;
      }
    }
  }
}
