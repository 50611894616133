#header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70vh;
  background-color: black;
  padding-top: 47px; /* menu / 2 */
  @include for-ipad-v {
    padding-top: 0;
  }
  // .banner-bg { will-change:transform }
  > .banner-bg-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    will-change: transform;
  }
  > .inner {
    position: relative;
    z-index: 1;
    @include inner-pad;
    width: 100%;
    .text {
      color: white;
      @include for-ipad-v {
        br {
          display: none;
        }
      }
    }
  }
  .scroll-down {
    bottom: -90px;
  }

  &.home {
    height: 100vh;
    > .banner-bg-wrap {
      height: 100vh;
    }
    @include for-ipad-v {
      height: 87vh;
      > .banner-bg-wrap {
        height: 87vh; // avoid resizing jump
      }
    }
    > .inner {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      @include for-phone {
        flex-direction: column;
      }
      .chars-h {
        display: none;
        @include for-phone {
          display: block;
          position: relative;
          z-index: 1;
          width: 130px;
          padding-right: 30px;
          padding-bottom: 15px;
          border-bottom: solid 1px white;
          margin-bottom: 25px;
        }
      }
      .table-wrap {
        display: table;
        > div {
          display: table-cell;
          vertical-align: top;
          padding-bottom: 15px;
        }
        .chars {
          _flex-shrink: 0;
          margin-top: 8px;
          width: 110px;
          padding-right: 25px;
          border-right: solid 1px white;
          @include for-ipad-v {
            width: 90px;
            padding-right: 15px;
          }
        }
        .text {
          padding-left: 30px;
          h1 {
            margin-top: -10px;
          }
          .sub2 {
            font-weight: 400;
            margin-top: 15px;
          }
          .sub3 {
            font-weight: 700;
            margin-top: 20px;
            span {
              &::after {
                content: '|';
                margin: 0 10px;
              }
              &:last-of-type::after {
                content: '';
              }
              @include for-ipad-v {
                display: block;
                &::after {
                  display: none;
                }
              }
            }
          }
        }
        @include for-phone {
          .chars {
            display: none;
          }
          .text {
            padding-left: 0;
          }
        }
      }
    }
    .scroll-down {
      bottom: 85px;
      button {
        background-color: transparent;
      }
    }
  }

  &.cases-list {
    justify-content: flex-end;
    padding-bottom: 70px;
    @include for-ipad-v {
      padding-bottom: 50px;
    }
    @include for-phone {
      padding-bottom: 35px;
    }
  }

  // &.contact { height:50vh;
  //   > .banner-bg-wrap { height:50vh; }
  // }

  &.not-found {
    height: 100vh;
    > .banner-bg-wrap {
      height: 100vh;
    }
    > .inner {
      display: flex;
      justify-content: center;
    }
    .scroll-down {
      display: none;
    }
  }
}
