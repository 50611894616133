* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$poynter: 'poynter-oldstyle-display', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft Yahei', '微软雅黑', sans-serif;
$futura: 'futura-pt', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft Yahei',
  '微软雅黑', sans-serif;
$trade_gothic: 'trade-gothic-next', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft Yahei', '微软雅黑', sans-serif;

@mixin smalltext {
  font-family: $futura;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

body {
  font-family: $futura;
}

#header h1 {
  font-weight: 700;
  font-size: 48px;
}
h1 {
  font-family: $poynter;
  font-weight: 600;
  font-size: 34px;
}
h2 {
  font-family: $poynter;
  font-weight: 600;
  font-size: 29px;
  line-height: 1.33;
}
h3 {
  font-family: $poynter;
  font-weight: 600;
  font-size: 23px;
  line-height: 1.33;
}
h4 {
  @include smalltext;
  font-weight: 500;
  font-size: 15px;
}

@include for-small-desktop {
  #header h1 {
    font-size: 40px;
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 25px;
  }
  h3 {
    font-size: 20px;
  }
}

@include for-phone {
  #header h1 {
    font-size: 32px;
  }
  #header.home h1 {
    font-size: 36px;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 21px;
  }
  h4 {
    font-size: 14px;
  }
}
@include for-phone-smallest {
  #header h1 {
    font-size: 25px;
  }
  #header.home h1 {
    font-size: 28px;
  }
  h1 {
    font-size: 23px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
}

h4.tag {
  display: inline-block;
  padding: 6px 12px;
  background-color: $green;
  color: white;
  margin-top: 30px;
  + h3 {
    margin-top: 20px;
  }
}
h5 {
  font-family: $futura;
  font-weight: 500;
  font-size: 21px;
  line-height: 1.33;
  margin-top: 10px;
  color: $green;
}

h4 {
  + h1,
  + h2,
  + h3 {
    margin-top: 15px;
  }
}

h1,
h2 {
  + p {
    margin-top: 30px;
  }
}
h3 + p {
  margin-top: 15px;
}

img + h4 {
  margin-top: 30px;
}

p {
  font-family: $trade_gothic;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.66;
  margin-top: 15px;
}
.breadcrumb p {
  font-family: $futura;
  font-weight: 400;
  font-size: 16px;
  color: $light-grey;
  .arrow {
    font-weight: 700;
    color: white;
    margin: 0 10px;
  }
  .active {
    font-weight: 700;
    color: white;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

p.more {
  margin-top: 20px;
  a {
    font-family: $futura;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    color: $green;
  }
}

#menu li a {
  @include smalltext;
  font-weight: 600;
  font-size: 14px;
  color: white;
}
.button {
  @include smalltext;
  font-weight: 700;
  font-size: 15px;
  color: $green;
  border: solid $green;
  margin-top: 40px;
  &.white {
    color: white;
    border-color: white;
  }
}
.credit p {
  font-family: $futura;
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.3);
}

ul.text-only {
  list-style: none;
  li {
    font-family: $futura;
    font-size: 15px;
    line-height: 21px;
    margin-top: 8px;
  }
}
.main .text {
  ul {
    list-style-type: none;
    margin-top: 15px;
    @include for-ipad-v {
      padding-left: 20px;
    }
    li {
      font-family: $trade_gothic;
      font-weight: 300;
      font-size: 16px;
      line-height: 1.66;
      margin-top: 0;
      padding-left: 23px;
      &:before {
        content: '– ';
        margin-left: -22px;
        margin-right: 8px;
      }
    }
  }
}

#footer {
  h4 + h4 {
    margin-top: 20px;
  }
  ul {
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.6);
  }
}

// BG-LINK, BUTTONS
a.bg-link {
  position: relative;
  display: inline-block;
  padding: 5px 7px;
  position: relative;
  left: -7px;
  color: $green;
  span.bg {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $green;
    overflow: hidden;
    transition: width 0.2s;
    span.inner-text {
      display: block;
      padding: 5px 7px;
      white-space: nowrap;
      color: white;
    }
  }
  &.button {
    left: 0;
    padding: 10px 15px;
    span.bg {
      span.inner-text {
        padding: 10px 15px;
        white-space: nowrap;
      }
    }
    &.filled-green {
      span.bg {
        width: 100%;
      }
    }
    &.white {
      span.bg {
        background-color: white;
        span.inner-text {
          color: $dark-green;
        }
      }
    }
  }
  &:hover {
    span.bg {
      width: 100%;
    }
  }
}

a.line-link {
  display: inline-block;
  > .inner {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      border-bottom: solid 1px;
      transform: scaleX(0.5);
      opacity: 0;
      transition: transform 0.15s, opacity 0.3s;
    }
  }
  &:hover,
  &.active {
    > .inner::after {
      transform: scaleX(1);
      opacity: 1;
    }
  }
}
.breadcrumb a.line-link > .inner::after {
  bottom: 1px;
}
h4 a.line-link > .inner::after {
  bottom: -3px;
}
h5 a.line-link > .inner::after {
  bottom: -2px;
}

.block .text h4 {
  margin-top: 40px;
}
.text > *::first-child {
  margin-top: 0;
}
